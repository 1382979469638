$font-family-roboto: "Roboto", "Helvetica", "Arial", sans-serif;

.MuiAccordionSummary-root {
  min-height: 149px;
}

.MuiAccordionSummary-content {
  margin-top: 0px;
  margin-bottom: 0px;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin-top: 0px;
  margin-bottom: 0px;
}

.run-header-table {
  font-family: $font-family-roboto;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.accordion {
  background-color: transparent;
}

.accordion-detail {
  background-color: transparent;
}

.td-8 {
  width: 20em;
}

.td-6 {
  width: 6em;
  text-align: right;
  white-space: nowrap;
}

.accordion-container {
  border: 1px solid silver;
  border-radius: 0.9em !important;
  padding-left: 0;
  padding-right: 0;
}

.modal-label {
  font-size: 17px;
}
.main-data-container {
  border: 1px solid silver;
  border-radius: 0.9em !important;
  padding: 0.8em;
}
