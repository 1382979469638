@import "../../styles/variables.scss";

$pager-container-height: 50px;

.items-list-detail {
  .left-side {
    height: calc(100vh - $title-bar-height);
    border-right: $line-style;

    .item-list-loading {
      height: calc(100vh - $title-bar-height);
    }

    .item-list-container {
      height: calc(100vh - $title-bar-height - $pager-container-height);
      overflow-y: scroll;
    }

    .pager-container {
      border-top: $line-style;
      height: $pager-container-height;
    }
  }

  .right-side {
    height: calc(100vh - $title-bar-height);
    padding-right: 24px;

    .item-view-loading {
      height: calc(100vh - $title-bar-height);
    }

    .item-view-container {
      height: calc(100vh - $title-bar-height);
      overflow-y: scroll;
    }
  }
}
