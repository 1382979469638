.filter-bar {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 2px;
  margin-right: 10px;

  .search-container {
    width: 24px;
    max-width: 24px;
  }

  .search-icon {
    font-size: 1.5rem;
  }

  .input {
    margin-right: 10px;
    background-color: transparent;
    border: 0;
    font-size: 1rem;
    color: #fff;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff80;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff80;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff80;
  }

  .clear-container {
    text-align: end;
    width: 15px;
    max-width: 15px;
  }

  .clear-icon {
    font-size: 1rem;
    vertical-align: top;
  }
}
