.text-box {
  .warning-border {
    border-left: 2px solid #fcb414;
  }

  .warning {
    background-color: #fcb41426;

    .icon {
      color: #ff6a00;
    }
  }

  .info-border {
    border-left: 2px solid #0288d1;
  }

  .info {
    background-color: #0288d126;

    .icon {
      color: #047cbd;
    }
  }

  .error-border {
    border-left: 2px solid #ff0000;
  }

  .error {
    background-color: #ff000026;

    .icon {
      color: #ff0000;
    }
  }
}
