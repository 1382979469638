@import "../../../styles/variables.scss";

$pager-container-height: 35px;

.dashboard-items-list-detail {
  .left-side {
    border-right: $line-style;

    .item-list-loading {
      height: calc(100vh - $title-bar-height);
    }
    .item-list-container {
      height: calc(100vh - $title-bar-height - $pager-container-height - 120px);
      overflow-y: scroll;
      border-top: $line-style;
    }

    .pager-container {
      border-top: $line-style;
      height: $pager-container-height;
    }
  }
  .right-side {
    padding-right: 0px;
    padding-left: 5px;

    .item-view-loading {
      height: calc(100vh - $title-bar-height - 150px);
    }

    .item-view-container {
      height: calc(100vh - $title-bar-height - 110px);
      overflow-y: auto;
    }
  }
}
