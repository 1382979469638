@import "../../styles/variables.scss";

$user-container-height: 60px;
$logout-container-height: 40px;
$footer-height: 170px;

.main-container {
  height: 100vh;
  overflow-y: hidden;

  .left-side-panel {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    border-right: $line-style;

    .user-container {
      height: $user-container-height;
      border-bottom: $line-style;
      font-size: 1.1rem;
    }

    .menu-container {
      height: calc(
        100vh - $user-container-height - $logout-container-height -
          $footer-height
      );
    }

    .menu-container-not-expanded {
      height: calc(100vh - $user-container-height - $logout-container-height);
    }

    .logout-container {
      height: $logout-container-height;
    }

    .menu-item {
      .col-icon {
        padding-left: 20px;
        width: 30px;
      }

      .icon {
        width: 20px;
      }

      &:hover {
        background-color: rgba($cleanfix-color, 0.1);
      }
    }

    .menu-item-current {
      color: $cleanfix-color;

      background-color: rgba($cleanfix-color, 0.1);

      &:hover {
        background-color: rgba($cleanfix-color, 0.1);
      }
    }

    .footer {
      height: $footer-height;
      border-top: $line-style;

      font-size: 0.75rem;

      .logo-brand {
        width: 160px;
      }
    }

    a {
      text-decoration: none;
      color: #666666;
    }
  }

  .left-side-panel-small {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
  }

  .right-side-panel {
    padding-left: 24px;
    height: "100vh";
    overflow-x: hidden;

    .options-container {
      height: 100vh;
    }
  }
}
