.modal-window {
  .MuiPaper-root {
    width: 100vw !important;
  }

  .modal-content {
    font-size: 0.875rem;
    line-height: 1.43;
  }

  .modal-header {
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 15px;
    font-size: 1.3em;
    border-bottom: 0;

    h3 {
      margin-bottom: 0 !important;
      margin-top: 10px !important;
      font-size: 1.17em;
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 0px 30px;
    max-height: 70vh;
    overflow-y: auto;
  }

  .modal-footer {
    padding: 10px;
    text-align: right;
    border-top: 0;
    overflow-x: hidden;
  }
}
