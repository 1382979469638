@import "../../styles/variables.scss";

.robots-left-side {
  .on-line {
    color: #46a05a;
  }

  .off-line {
    color: $disable-color;
  }

  .no-property {
    color: orange;
  }

  .current-row {
    .no-property {
      color: white;
    }
  }
}

.robots-right-side {
  .robot-information {
    font-size: 14px;

    .title {
      font-size: 1.25rem;
    }

    .rectangle {
      width: 7rem;
      border: 2px solid $cleanfix-color;
      border-radius: 8px;
      color: $cleanfix-color;
      font-weight: bold;
    }

    .on-line {
      color: #46a05a;
    }

    .off-line {
      color: $disable-color;
    }

    .no-license-or-type {
      font-size: 1.2rem;
    }
  }

  .tab-header {
    margin-left: -24px;
    margin-right: -24px;

    .MuiTab-textColorPrimary {
      font-weight: bold;
      letter-spacing: 3px;
    }
  }

  .system-tab {
    font-size: 14px;
    max-width: 600px;
  }

  .controller-tab {
    font-size: 14px;
    max-width: 600px;
  }

  .settings-tab {
    font-size: 14px;
    max-width: 800px;

    .pin-save {
      color: $disable-color;
      &:hover {
        color: $cleanfix-color;
      }
    }
  }

  .charging-tab {
    font-size: 14px;
    max-width: 600px;

    .rectangle {
      margin-left: auto;
      width: 4rem;
      border: 2px solid $cleanfix-color;
      border-radius: 8px;
      color: $cleanfix-color;
      font-weight: bold;
    }
  }

  .subtitle-in-tab {
    font-size: 17px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 49px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.robot-access-settings-modal {
  .td-warp {
    word-wrap: anywhere;
  }

  .config-container {
    background-color: rgba(0, 111, 182, 0.08);
  }
}

.rectangle {
  width: 7rem;
  border: 2px solid $cleanfix-color;
  border-radius: 8px;
  color: $cleanfix-color;
  font-weight: bold;
}
