@import "../../styles/variables.scss";

.dashboard-container {
  padding-left: 12px;
  padding-right: 12px;
}

.dashboard-component-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
}

.dashboard-component-title {
  font-size: 1.4rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.dashboard-component-labels {
  font-size: 0.9rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.dashboard-component-subtitle {
  font-size: 1.2rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.bar-height {
  height: calc(50vh - 200px);
}

.bar-height-mission {
  height: calc(50vh - 280px);
}

.bar-height-fleet-full {
  height: calc(50vh - 170px);
}

.bar-height-fleet-statistics {
  height: calc(50vh - 140px);
  overflow-y: auto;
}

.bar-height-mission-full {
  height: calc(50vh - 200px);
}

.tooltip-row-flex {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.tooltip-color-box {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.slice-tooltip {
  background: white;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.legend-container {
  display: flex;
  font-size: 0.8rem;
}

.legend-item--inactive {
  opacity: 0.5;
}

.legend-color-box {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.legend-container-missions {
  margin-top: 2px;
  display: flex;
  font-size: 0.8rem;
  flex-wrap: wrap;
  height: 80px;
  overflow-y: auto;
}

.legend-item {
  margin: 2px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
