@import "../../styles/variables.scss";

.title-bar-container {
  background-color: $cleanfix-color;
  color: white;
  height: $title-bar-height;

  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);

  .title {
    font-size: 1.3rem;
    font-weight: 500;
  }

  .text-search-column {
    max-width: 400px;
  }

  .filter-button-column {
    max-width: 40px;
  }

  .icon-button {
    color: rgba(white, 0.8);

    &:hover {
      background-color: transparent;
      color: white;
    }
  }
  .icon-button-active {
    color: #ff7700;

    &:hover {
      background-color: transparent;
      color: white;
    }
  }
}
