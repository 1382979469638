$loginContainerHeight: 360px;
$loginContainerWidth: 600px;
$borderRadius: 8px;
$sideWidth: calc($loginContainerWidth / 2);

.unauthenticated {
  height: 100vh;
  overflow-y: hidden;
  background-image: url("../../assets/authenticated-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .login-container {
    margin-top: calc((100vh - $loginContainerHeight) / 2);
    margin-left: calc((100vw - $loginContainerWidth) / 2);
    height: $loginContainerHeight;
    width: $loginContainerWidth;
    border-radius: $borderRadius;

    -webkit-box-shadow: 10px 10px 26px -6px rgba(38, 38, 38, 1);
    -moz-box-shadow: 10px 10px 26px -6px rgba(38, 38, 38, 1);
    box-shadow: 10px 10px 26px -6px rgba(38, 38, 38, 1);

    .left-side {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
      background-color: #dcdcdc;
      width: $sideWidth;
      height: $loginContainerHeight;
      font-size: 1.3rem;
      padding-top: 130px;
    }

    .right-side {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
      background-color: white;
      width: $sideWidth;
      height: $loginContainerHeight;
      font-size: 1.3rem;
      padding-top: 70px;

      .admin-panel-img {
        width: calc($sideWidth / 3);
      }

      .logo-brand {
        width: calc(($sideWidth - 40px) / 2);
      }
    }
  }

  // .logo-brand {
  //   width: 25vw;
  //   margin-top: 20vh;
  // }
}
