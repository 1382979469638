@import "./variables.scss";
@import "./toast.scss";
@import "./bootstrap.scss";
@import "./variables.scss";

.header-cell {
  font-weight: bold !important;
}

.cursor-pointer {
  cursor: pointer;
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
}

.current-row {
  background-color: rgba($cleanfix-color, 0.35);
}

.color-cleanfix {
  color: $cleanfix-color;
}

.bg-cleanfix {
  background-color: $cleanfix-color;
}

.color-delete {
  color: #cf1515;
}

.btn-outline-cleanfix {
  width: 10rem;
  border: 1px solid $cleanfix-color;
  color: $cleanfix-color;

  &:hover {
    background-color: $cleanfix-color;
    color: white;
  }
}

.btn-outline-cleanfix-no-border {
  width: 10rem;
  border: 0;
  color: $cleanfix-color;

  &:hover {
    background-color: $cleanfix-color;
    color: white;
  }
}

.btn-cleanfix {
  width: 10rem;
  border: 1px solid $cleanfix-color;
  background-color: $cleanfix-color;
  color: white;

  &:hover {
    color: white;
    border-color: $cleanfix-color-highlight;
    background-color: $cleanfix-color-highlight;
  }
}

.icon-button {
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.btn-icon-button {
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: #323232;
  border-radius: 50%;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    background-color: #fafafa;
    color: #292929;
  }
}

.text-no-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.badge-container {
  .badge {
    border: 1px solid $cleanfix-color;
    color: $cleanfix-color;
    cursor: pointer;

    &:hover {
      background-color: $cleanfix-color;
      color: white;
    }
  }

  .rounded-pill {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
  }
}

.text-disable {
  color: $disable-color;
}

.bg-disable {
  background-color: $disable-color;
}

.mandatory {
  color: red;
  font-size: 1.1rem;
  line-height: 1rem;
}
